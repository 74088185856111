import React, { useEffect } from "react";
import Awareness from "../pages/Awareness";
import tagManagerEvents from "../utils/GoogleTagManager";
import { ApiName } from "../Constant/ApiName";
import { fetchApiGet } from "../utils/FetchApi";
import { encode as base64_encode } from "base-64";
import TagManager from "react-gtm-module";

type Props = {
  jwtToken: string | null;
};

let adNetwork = "";
const AwarenessScreen = (props: Props) => {
  useEffect(() => {
    if (props.jwtToken) {
      getUrlUtm();
    }
  }, [props.jwtToken]);

  useEffect(() => {
    setTimeout(() => {
      gtmInitialization();
    }, 1000);
  }, []);
  const gtmInitialization = () => {
    const tagManagerArgs = {
      gtmId: "GTM-W9PK8M64",
    };
    TagManager.initialize(tagManagerArgs);
  };

  // function
  const getUrlUtm = () => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const utmSource: string | null = searchParams.get("utm_source");
    if (utmSource) {
      checkUtms(utmSource);
    }
    getFlow(utmSource);
  };

  const checkUtms = async (_utm: string | null) => {
    try {
      let response = await fetchApiGet(props.jwtToken, ApiName.utm_list);

      if (response.status === 200) {
        response.response.map((e: any) => {
          if (_utm === e.sf_utm) {
            adNetwork = e.ad_network;
          }
        });
      }
      console.warn("adNEtwork", adNetwork, "----", _utm);
    } catch (error) {
      console.log("check utm error", error);
    }
  };

  const getFlow = async (_utm: string | null) => {
    try {
      let endPoint = `${ApiName.awarenessFLow}?utm_source=${_utm}`;
      let response = await fetchApiGet(props.jwtToken, endPoint);

      if (response.status === 0) {
        if (response.result.toLowerCase() !== "already subscribed") {
          if (
            adNetwork.toLowerCase() === "tiktok" ||
            adNetwork.toLowerCase() === "tik tok"
          ) {
            console.log("in tiktok");
            tagManagerEvents("SUBSCRIBE", "econTikTok");
          } else if (adNetwork.toLowerCase() === "google") {
            tagManagerEvents("SUBSCRIBE", "econceptions");
          } else {
            tagManagerEvents("SUBSCRIBE", _utm);
          }
        }
        let url = window.location.origin;
        let encodedMsisdn = base64_encode(response.msisdn);
        let _package = "";
        if (response.subPackage.toLowerCase() === "pre") {
          _package = "prepaid";
        } else {
          _package = "postpaid";
        }

        let encodedPackage = base64_encode(_package);

        url = `${url}/home?user=${encodedMsisdn}&package=${encodedPackage}`;
        if (url.includes("http:")) {
          url = url.replace("http", "https");
        }
        window.location.replace(url);
      } else {
        navigateToHome();
      }
    } catch (error) {
      navigateToHome();

      console.log("get flow error" + error);
    }
  };

  const navigateToHome = () => {
    let url = window.location.origin;
    url = `${url}/landing`;
    if (url.includes("http:")) {
      url = url.replace("http", "https");
    }
    window.location.replace(url);
  };

  return <Awareness />;
};

export default AwarenessScreen;
